//styles
import './admin.scss'
import { arrow } from '../../assets/admin/adminIcons'

//react
import { useEffect, useState } from 'react'

//components
import Layout from '../../components/general/layout/layout'
import Input from '../../components/general/input/input'
import AdminPaginationButton from '../../components/admin/adminPaginationButton/adminPaginationButton'
import AdminCard from '../../components/admin/adminCard/adminCard'

//network
import { authorizedRequest } from '../../network/requests'
import { contactUrl } from '../../network/urls'

export enum statusTypes {
    new='New',
    inProgress='In Progress',
    closed='Closed'
}


export type adminCard = {
    id: number
    name: string
    email: string
    message: string
    placedDate: Date
    lastUpdateDate: Date
    phoneNumber: string
    status: statusTypes
}

type adminCardResponse = {
    id: number
    name: string
    email: string
    message: string
    date: number
    last_update_date: number
    phone_number: string
    status: statusTypes
}

const Admin = () => {
    const [ request, setRequest ] = useState('')

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const [adminCards, setAdminCards] = useState<adminCard[]>([])

    const createNumberRange = (number: number) => {
        const start = Math.max(1, Math.min(number - 3, totalPages - 6)); // Ensure the range starts within valid bounds
        const end = Math.min(totalPages, start + 6); // Ensure the range ends within totalPages
        const range = [];

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    }
    

    useEffect(() => {
        authorizedRequest(contactUrl + `?page=${page}&request=${request}&per_page=${6}`, 'GET')
        .then((response) => {
            if(response && response.result){
                setAdminCards(response.result.contacts.map((item: adminCardResponse) => {
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        message: item.message,
                        placedDate: new Date(item.date * 1000),
                        lastUpdateDate: new Date(item.last_update_date * 1000),
                        status: item.status,
                        phoneNumber: item.phone_number
                    }
                }))
                setTotalPages(Math.ceil(response.result.total_contacts/6))
            }
        })
       
    }, [page, request])

    return (
        <Layout>
            <div className="admin container">
                <Input placeholder='Search' onChange={(e) => {
                    setRequest(e.target.value)
                }} />
                <div className="admin-cards-container">
                    {
                        adminCards.map((card) => {
                            return (
                                <AdminCard
                                    id={card.id}
                                    name={card.name}
                                    email={card.email}
                                    message={card.message}
                                    placedDate={card.placedDate}
                                    lastUpdateDate={card.lastUpdateDate}
                                    phoneNumber={card.phoneNumber}
                                    status={card.status}
                                />
                            )
                        })
                    }
                </div>
                <div className="admin-pagination-container">
                    <AdminPaginationButton onClick={() => {
                        setPage(page-1 > 0 ? page-1 : 1)
                    }} >
                        {arrow}
                    </AdminPaginationButton>

                    {
                        createNumberRange(page).map((elem) => {
                            return (
                                <AdminPaginationButton active={page === elem} onClick={() => {
                                    setPage(elem)
                                }} >
                                    <p>{elem}</p>
                                </AdminPaginationButton>
                            )
                        })
                    }

                    <AdminPaginationButton onClick={() => {
                        setPage(page+1 > totalPages ? totalPages : page+1)
                    }} >
                        {arrow}
                    </AdminPaginationButton>
                </div>
            </div>
        </Layout>
    )
}

export default Admin