export const authorizedRequest = async (url: string, method: string, body?: object) => {
	const token = localStorage.getItem('accessToken')
	
	const request: object = body ? {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body)
	} : {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		}
	}

	if (!token || token === '') {
		window.location.href = 'http://' + window.location.host + '/login'
		localStorage.setItem('accessToken', '')
		return undefined
	}

	const response = await fetch(url, request)
	if (response &&(response.status === 200 || response.status === 201)) {
		return await response.json()
	}else{
		return undefined
	}	

}

export const unauthorizedRequest = async (url: string, method: string, body?: object) => {
	const request: object = body ? {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body)

	} : {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		}
	}

	const response = await fetch(url, request)

	if (response.status === 200) {
		return await response.json()
	} else {
		return response.status
	}
}
